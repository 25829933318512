import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import Arrow from "@/assets/icons/arrow-right.svg";

export default function CustomSwiper({
  children,
  virtual,
  view,
  space,
  navigate,
  navStyles = {
    container: "absolute flex items-center right-0 z-10 w-full h-full",
    left: "bg-white absolute left-[-10px] p-2 md:p-0",
    right: "bg-white absolute right-[-10px] p-2 md:p-0",
  },
  containerStyles = "",
  touchStartForcePreventDefault,
  center,
  reverseDirection,
  setActive,
  getRef,
  hideArrowRight,
  hideArrowLeft,
  data,
  ...rest
}) {
  const swiperRef = useRef(null);

  useEffect(() => {
    const initializeSwiper = () => {
      register();
      let autoplayConfig;
      if (navigate) {
        autoplayConfig = false;
      } else {
        autoplayConfig = {
          reverseDirection: !!reverseDirection,
          disableOnInteraction: true,
          delay: rest.freeMode ? 1 : 2500,
        };
      }

      const params = {
        spaceBetween: space,
        speed: rest.freeMode ? 10000 : 1000,
        loop: true,
        className: "cards h-full",
        centeredSlides: false,
        breakpoints: {
          1400: {
            slidesPerView: view.desktop,
          },
          700: {
            slidesPerView: view.laptop,
          },
          576: {
            slidesPerView: view.md,
          },
          320: {
            slidesPerView: view.mobile,
          },
        },
        autoplay: autoplayConfig,
        ...rest,
      };
      if (swiperRef.current) {
        Object?.assign(swiperRef.current, params);
        const swiperInstance = swiperRef.current;
        if (typeof swiperInstance.initialize === "function") {
          swiperInstance.initialize();
        }

        getRef && getRef(swiperRef);
      }
    };
    initializeSwiper();
  }, [
    children,
    rest,
    virtual,
    space,
    view.desktop,
    view.laptop,
    view.md,
    reverseDirection,
    view.mobile,
    center,
    navigate,
    getRef,
  ]);

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper?.slideNext();
      if (setActive && swiperRef.current.swiper?.activeIndex && data) {
        setActive(data[swiperRef.current.swiper?.activeIndex].id);
      }
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper?.slidePrev();
      if (setActive && swiperRef.current.swiper && data) {
        setActive(data[swiperRef.current.swiper?.activeIndex].id);
      }
    }
  };

  return (
    <div className="relative max-w-full">
      {navigate && (
        <div className={navStyles?.container}>
          <div
            onClick={handlePrevSlide}
            className={`${navStyles?.left} rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer ${hideArrowLeft ? "hidden" : "flex"}`}
          >
            <Arrow
              width="41"
              height="38"
              className={`${navStyles?.arrowColor ?? "fill-purple"} rotate-180`}
            />
          </div>
          <div
            onClick={handleNextSlide}
            className={`${navStyles?.right} rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer ${hideArrowRight ? "hidden" : "flex"}`}
          >
            <Arrow
              width="41"
              height="38"
              className={`${navStyles?.arrowColor ?? "fill-purple"}`}
            />
          </div>
        </div>
      )}
      <div className={containerStyles}>
        <swiper-container ref={swiperRef} init={false}>
          {children}
        </swiper-container>
      </div>
    </div>
  );
}
